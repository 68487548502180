import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { PATH_AFTER_FROM_LOGIN, SITE_TITLE } from '@src/config/constants';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { userAtom } from '@src/recoil/atoms/user';
import { FormLogin } from '@src/services/auth/components/FormLogin';

export interface LoginProps {}

/**
 * @name Login
 * @description Login page with a simple form and some links to the
 * standard "forgot" and "reset" password flows
 */
export const Login = (_props: LoginProps) => {
  // Hooks
  const { data } = useGetCustomerInfoQuery();
  const { visitor } = useRecoilValue(userAtom);
  const { asPath, push, query } = useRouter();

  // Setup
  const emptyAccount = query.account as string;
  const redirect = asPath.startsWith('/login') ? PATH_AFTER_FROM_LOGIN : asPath;
  const info = data?.customerInfo;

  // Styles
  const cssComponent = `flex flex-1 flex-col items-center justify-center bg-sea-salt`;

  // Life Cycle
  React.useEffect(() => {
    if (!visitor && !emptyAccount) push(redirect);
  }, [visitor]);

  return (
    <div className={cssComponent}>
      <Head>
        <title>{`Login | ${SITE_TITLE}`}</title>
      </Head>

      <main className="ui-container-sm mt-8x mb-10x">
        <h1 className="ui-page-heading-medium text-center">
          You look great today
        </h1>

        <FormLogin redirect={redirect} info={info} />
      </main>
    </div>
  );
};

export default Login;
