import * as React from 'react';
import classnames from 'classnames';

export interface AppErrorMessageProps {
  className?: string;
  message?: string;
}

/**
 * @name AppErrorMessage
 * @description Used to display errors to our user.
 */
export const AppErrorMessage = (props: AppErrorMessageProps) => {
  const { className, message } = props;

  // Styles
  const tailwind = 'block text-14 text-center';
  const cssComponent = classnames(tailwind, className);

  // 🔌 Short Circuit
  if (!message) return null;

  return <div className={cssComponent}>{message}</div>;
};
