import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { SITE_SURVEY_CTA, SITE_SURVEY_PATH } from '@src/config/constants';
import { GetCustomerCartQuery } from '@src/generated/hooks';
import { useApp } from '@src/hooks/useApp';
import { useFirebase } from '@src/hooks/useFirebase';
import { useFormInput } from '@src/hooks/useFormInput';
import { useFormValidation } from '@src/hooks/useFormValidation';
import { userAtom } from '@src/recoil/atoms/user';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { AppErrorMessage } from '@src/services/app/components/AppErrorMessage';
import { AppLink } from '@src/services/app/components/AppLink';

export interface FormLoginProps {
  className?: string;
  info?: GetCustomerCartQuery['customerInfo'];
  redirect: string;
}

/**
 * @name FormLogin
 * @description Basic form for login which makes use of basic HTML validation
 */
export const FormLogin = (props: FormLoginProps) => {
  const { className, info, redirect } = props;

  // Hooks
  const formRef = React.useRef<HTMLFormElement>(null);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = React.useState(false);
  const inputEmail = useFormInput(info?.email || '');
  const inputPassword = useFormInput('');
  const { signIn, resetPassword } = useFirebase();
  const { push, query } = useRouter();
  const { email } = useRecoilValue(userAtom);
  const { toggleLoader } = useApp();

  const account = query.account as string;
  const [errorMessage, setErrorMessage] = React.useState('');
  const isValid = useFormValidation(formRef, [
    inputEmail.value,
    inputPassword.value
  ]);

  // Styles
  const cssBtn = classnames('w-[240px] mb-2x', { disabled: !isValid });
  const cssComponent = classnames('w-[290px] mx-auto text-center', className);

  // Handlers
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage('');
    toggleLoader(true);

    const email = inputEmail.value.toLowerCase();
    const password = inputPassword.value;

    try {
      await signIn(email, password, redirect);
    } catch (e) {
      setErrorMessage('Invalid email address or password');
    }

    toggleLoader(false);
  };
  const onForgotPassword = () => {
    if (!info?.email) {
      push('/forgot-password');
      return;
    }

    try {
      setErrorMessage('');
      resetPassword(info.email);
    } catch (error: unknown) {
      const message = `We've encountered an unknown error, please refresh the page and try again.`;
      const msg = error instanceof Error ? error.message : message;

      setErrorMessage(msg);
    }
  };

  // Lifecycle
  React.useEffect(() => {
    if (account === 'unknown' && email) {
      setErrorMessage(`No profile found for ${email}.`);
    }
  }, [account]);

  React.useEffect(() => {
    if (!emailRef.current) return;

    emailRef.current.focus();
  }, []);

  React.useEffect(() => {
    if (info?.email && passwordRef.current) {
      inputEmail.setValue(info.email.toLowerCase());
      passwordRef.current.focus();
      setDisabled(true);
    }
  }, [info?.email, passwordRef]);

  return (
    <form
      className={cssComponent}
      onSubmit={onSubmit}
      ref={formRef}
      style={{ maxWidth: 325 }}
    >
      <AppErrorMessage className="mb-2x text-red" message={errorMessage} />

      <label className="sr-only" htmlFor="email">
        Email
      </label>
      <input
        autoComplete="email"
        className="mb-2x"
        disabled={disabled}
        id="email"
        onChange={inputEmail.onChange}
        placeholder="Email"
        ref={emailRef}
        required
        type="email"
        value={inputEmail.value}
      />

      <label className="sr-only" htmlFor="name">
        Password
      </label>
      <input
        autoComplete="password"
        className="mb-4x"
        id="password"
        onChange={inputPassword.onChange}
        placeholder="Password"
        required
        ref={passwordRef}
        type="password"
        value={inputPassword.value}
      />

      <HaldiButton className={cssBtn} type="submit">
        Log In
      </HaldiButton>

      <button
        className="mx-auto block text-14 underline text-gray-5 font-central-no1-light"
        type="button"
        onClick={onForgotPassword}
      >
        Forgot password?
      </button>

      <div className="mt-4x block text-16 font-central-no1-light">
        New here?
        <AppLink
          className="ml-1 text-gray-5 font-semibold"
          href={SITE_SURVEY_PATH}
        >
          {SITE_SURVEY_CTA}
        </AppLink>
      </div>

      {/* <h1 className="mt-3x mb-4x mx-auto w-full text-center border-0 border-b-[1px] border-solid border-b-gray-1 leading-[0px]">
        <span className="text-12 text-gray-5 bg-white px-1x">OR</span>
      </h1>
      <SignInGoogle className="w-full" setErrorMessage={setErrorMessage} />
      <SignInFacebook
        className="w-full mt-2x"
        setErrorMessage={setErrorMessage}
      /> */}
    </form>
  );
};
